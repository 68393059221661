import React, { useState } from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

import "../styles/styles.scss";

import LOGO from "../img/logo/logo.svg";

// ICONS
import { IoMdClose } from "react-icons/io";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { BsArrowRight, BsChevronDown } from "react-icons/bs";
import flagUK from "../../static/img/flag-uk.svg";
import flagEU from "../../static/img/flag-eu.svg";

import { StickyContainer, Sticky } from "react-sticky";

import MailChimpSignup from "./MailChimpSignup";
import Footer from "./Footer";

import GENERAL_CONFIGS from "../../content/pages/general.json";

class Header extends React.Component {
  state = {
    scrollDir: "up",
    whiteNav: false
  };

  componentDidMount() {
    this.lastScrollTop = null;
    document.addEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const { scrollDir } = this.state;
    if (typeof window !== "undefined") {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > this.lastScrollTop) {
        // console.log("down")

        if (scrollDir === "up") {
          this.setState({
            ...this.state,
            scrollDir: "down"
          });
        }
      } else {
        // console.log("up")

        if (scrollDir === "down") {
          this.setState({
            ...this.state,
            scrollDir: "up"
          });
        }
      }

      if (st > 0 && this.state.whiteNav === false) {
        this.setState({
          ...this.state,
          whiteNav: true
        });
      }

      if (st === 0 && this.state.whiteNav !== false) {
        this.setState({
          ...this.state,
          whiteNav: false
        });
      }

      this.lastScrollTop = st <= 0 ? 0 : st;
    }
  };

  render() {
    const { props } = this;
    const { HeaderExtension } = props;
    const { scrollDir, whiteNav } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query {
            allMdx(
              sort: { fields: [frontmatter___date], order: DESC }
              filter: { frontmatter: { posttype: { eq: "brands" } } }
            ) {
              edges {
                node {
                  excerpt
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    subtitle
                    description
                    featuredImage {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                    }
                    categories {
                      title
                      description
                      bullets {
                        text
                      }
                      featuredImage {
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                          )
                        }
                      }
                    }
                    thumbnail {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const megamenuRef = React.useRef();

          let allBrands = data.allMdx.edges;
          if (GENERAL_CONFIGS.header_navigation_brands) {
            allBrands = allBrands.filter(({ node }) =>
              GENERAL_CONFIGS.header_navigation_brands.includes(
                node.frontmatter.title
              )
            );
          }

          let megamenuOpen = false;
          if (typeof window !== "undefined" && megamenuRef.current) {
            megamenuOpen =
              window
                .getComputedStyle(megamenuRef.current)
                .getPropertyValue("--megamenu-visible")
                .indexOf("true") !== -1;
          }

          return (
            <>
              <div
                className={
                  "site-header" +
                  (scrollDir === "down" && megamenuOpen === false
                    ? " hidden"
                    : "") +
                  (whiteNav === true ? " white" : "")
                }
              >
                <div className="container">
                  <nav
                    className="navbar"
                    role="navigation"
                    aria-label="main navigation"
                  >
                    <div className="navbar-brand">
                      <Link className="navbar-item logo" to="/">
                        <img src={LOGO} alt="Logo" />
                      </Link>

                      <a
                        role="button"
                        className="navbar-burger burger"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarBasicExample"
                        onClick={this.props.handleSideNavToggle}
                      >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                      </a>
                    </div>

                    <div id="navbarBasicExample" className="navbar-menu">
                      <div className="navbar-start"></div>

                      <div className="navbar-end">
                        <Link className="navbar-item link">
                          <span>
                            Company <BsChevronDown className="cp-chevron" />
                          </span>

                          <div
                            className="cp-megamenu cp-minimenu fixed"
                            ref={megamenuRef}
                            style={{
                              visibility: "hidden",
                              opacity: 0
                            }}
                          ></div>
                          <div
                            className="cp-megamenu cp-minimenu"
                            ref={megamenuRef}
                            style={{
                              visibility: "hidden",
                              opacity: 0
                            }}
                          >
                            <Link className="navbar-item link" to="/company">
                              About
                            </Link>

                            {/* <Link className="navbar-item link" to="/team">
                              Team
                            </Link> */}
                          </div>
                        </Link>

                        <Link className="navbar-item link" to="/brands">
                          <span>
                            Brands <BsChevronDown className="cp-chevron" />
                          </span>

                          <div
                            className="cp-megamenu"
                            ref={megamenuRef}
                            style={{ visibility: "hidden", opacity: 0 }}
                          >
                            <div className="container">
                              <div className="columns">
                                {allBrands
                                  .sort(function(a, b) {
                                    return (
                                      GENERAL_CONFIGS.header_navigation_brands.indexOf(
                                        a.node.frontmatter.title
                                      ) -
                                      GENERAL_CONFIGS.header_navigation_brands.indexOf(
                                        b.node.frontmatter.title
                                      )
                                    );
                                  })
                                  .slice(0, 5)
                                  .map(({ node }) => {
                                    const image = getImage(
                                      node.frontmatter.thumbnail ||
                                        node.frontmatter.featuredImage
                                    );

								return (
								  <div
									className="column"
									key={node.frontmatter.title}
								  >
									<Link to={`/brands${node.fields.slug}`}>
									  <GatsbyImage
										image={image}
										alt={node.frontmatter.title}
									  />

									  <p className="cp-title">
										{node.frontmatter.title}{" "}
										<BsArrowRight />
									  </p>
									</Link>
								  </div>
								);
                                  })}

                                <div className="column">
                                  <Link to="/brands">
                                    <StaticImage
                                      src="../img/all_brands.svg"
                                      alt="View all brands"
                                    />

                                    <p className="cp-title">
                                      View all brands <BsArrowRight />
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>

                        <Link className="navbar-item link" to="/news">
                          News
                        </Link>

                        <Link className="navbar-item link" to="/contact">
                          Contact
                        </Link>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>

              {HeaderExtension}
            </>
          );
        }}
      />
    );
  }
}



const Layout = (props) => {
  const containerRef = React.useRef(null);

  const [sideNav, setSideNav] = React.useState(false);

  const handleSideNavToggle = () => {
    if (sideNav === true) {
      document.querySelector("html").style.overflowY = "auto";
    } else {
      document.querySelector("html").style.overflowY = "hidden";
    }

    setSideNav(!sideNav);
  };

  const { children, location } = props;

  return (
    // <LocomotiveScrollProvider
    //   options={{
    //     smooth: true,
    //     // ... all available Locomotive Scroll instance options
    //   }}
    //   // watch={
    //   //   [
    //   //     //...all the dependencies you want to watch to update the scroll
    //   //   ]
    //   // }
    //   containerRef={containerRef}
    // >
    <div className="app-wrapper" ref={containerRef}>
      <StickyContainer>
        <div className={"sidenav" + (sideNav ? " open" : "")}>
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-narrow logo-wrapper">
                <Link className="" to="/">
                  <img src={LOGO} alt="Logo" />
                </Link>
              </div>
              <div className="column close-wrapper">
                <div className="close">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSideNavToggle();
                    }}
                  >
                    <IoMdClose />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="links">
            <Link to={`/`} onClick={handleSideNavToggle}>
              Home
            </Link>

            <Link to="/company" onClick={handleSideNavToggle}>
              About
            </Link>

            {/* <Link to="/team" onClick={handleSideNavToggle}>
              Team
            </Link> */}

            <Link to="/brands" onClick={handleSideNavToggle}>
              Brands
            </Link>

            <Link to="/news" onClick={handleSideNavToggle}>
              News
            </Link>

            <Link to="/careers" onClick={handleSideNavToggle}>
              Careers
            </Link>

            <Link to="/faq" onClick={handleSideNavToggle}>
              FAQ
            </Link>

            <Link to="/contact" onClick={handleSideNavToggle}>
              Contact
            </Link>
          </div>
        </div>

        <div className={"app-container" + (sideNav ? " sidenav-open" : "")}>
          <div className="overlay" onClick={handleSideNavToggle}></div>

          <Header handleSideNavToggle={handleSideNavToggle} {...props} />

          {children}

          <Footer location={location} />
        </div>
      </StickyContainer>
    </div>
    // </LocomotiveScrollProvider>
  );
};

export default Layout;
